import React, { useRef, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../../images/bclose.png"
import White from "../../images/lazy.jpg"

const Quien = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: one.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: six.current.getBoundingClientRect().left, textAlign: `left`})

            console.log(props, "quien")

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const one = useRef("one")
    const two = useRef("two")
    const three = useRef("three")
    const four = useRef("four")
    const five = useRef("five")
    const six = useRef("six")
    
    const showHide = props.display ? 'block' : 'none'

    const eng = 
    <div>
        <p>
            "Who plays me, hears my voices”, shows a recent moment in the life of
            Gaston Lafourcade, a classical pianist and harpsichordist who, at the age of 83,
            enters a recording studio for the first time in his life to record a solo album and to
            join his daughter, Natalia Lafourcade, who during a recess period in her career,
            decides to embark on this adventure as a love letter to her father and as a way to
            enjoy what brings them together, beyond blood ties: their deep love for music.
        </p>
        <p>
            Produced by: Natalia Lafourcade & Juan Pablo López-Fonseca<br />
            Directed and Edited by: Bruno Bancalari<br />
            Sound Mixing: Alan Ortíz<br />
            Production Company: ELEFANTE<br />
            Format: Documentary<br />
            Duration: 48:00 min<br />
            Year: 2020<br />
            Photographs by: Edwin Erazo, Natalia Lafourcade, Bruno Bancalari & Juan Pablo López-Fonseca<br />
            Latin Grammy (2021) Nominee Best Long Form Music Video<br />
        </p>

    </div>

    const esp = 
    <div>
        <p>
            "Quien me tañe, escucha mis voces”
            El documental retrata un momento en la vida de Gastón Lafourcade,
            un músico clásico, pianista y clavecinista quien, a sus 83 años de edad,
            entra por primera vez a un estudio de grabación para grabar algunas de
            las piezas clásicas que más ama y para encontrarse con su hija, Natalia
            Lafourcade, quien durante un periodo de pausa en su carrera, decide
            hacerle este regalo a su padre como una carta de amor, con el fin de
            trabajar juntos por primera vez y disfrutar de aquello que los une más allá
            de un lazo sanguíneo: su profundo amor a la música.
        </p>    
        <p>
            Producción: Natalia Lafourcade y Juan Pablo López-Fonseca<br />
            Dirección, Dirección de Fotografía y Edición: Bruno Bancalari<br />
            Mezcla de Sonido: Alan Ortíz<br />
            Casa Productora: ELEFANTE<br />
            Formato: Documental<br />
            Duración: 48:00 min<br />
            Año: 2020<br />
            Fotografías por: Edwin Erazo, Natalia Lafourcade, Bruno Bancalari y Juan Pablo López-Fonseca<br />
            Nominado al Latin Grammy (2021) Mejor Video en Formato Largo<br />
        </p>
    </div>

    useLayoutEffect(()=>{

        const lazies = [one,two,three,four,five,six]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let picStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        picStyle = {

            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

   

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>{props.lang == "ES" ? props.info.titulo : props.info.title}</p>
                <div dangerouslySetInnerHTML={{__html: props.vid}}></div>
                <br />
                <img ref={one} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/1.jpg`} alt="1"></img>
                <br />
                <img ref={two} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/2.jpg`} alt="2"></img>
                <br />
                <img ref={three} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/3.jpg`} alt="3"></img>
                <br />
                <img ref={four} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/4.jpg`} alt="4"></img>
                <br />
                <img ref={five} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/5.jpg`} alt="5"></img>
                <br />
                <img ref={six} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/quien/6.jpg`} alt="6"></img>
                <br />
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
                
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Quien)