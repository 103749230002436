import React, { useRef, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../../images/bclose.png"
import White from "../../images/lazy.jpg"

const Nat = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: one.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: nine.current.getBoundingClientRect().left, textAlign: `left`})
        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const one = useRef("one")
    const two = useRef("two")
    const three = useRef("three")
    const four = useRef("four")
    const five = useRef("five")
    const six = useRef("six")
    const seven = useRef("seven")
    const eight = useRef("eight")
    const nine = useRef("nine")
    
    const showHide = props.display ? 'block' : 'none'

    const eng = 
        <div>
            <p>
                Directed by: Bruno Bancalari<br />
                Produced by: Juan Pablo López-Fonseca<br />
                Script: Natalia Lafourcade, Bruno Bancalari & Juan Pablo López-Fonseca<br />
                DoP: Bruno Bancalari & Juan Pablo López-Fonseca<br />
                Editing: Bruno Bancalari<br />
                Sound design & mixing: Alan Ortíz<br />
                Music: Natalia Lafourcade<br />
                Production Company: ELEFANTE<br />
                Format: Documental<br />
                Duration: 31:00 min<br />
                Year: 2017<br />
                Photographs by: Juan Pablo López-Fonseca<br />
                Sony Music Entertainment México S.A de C.V<br />
                Latin Grammy (2017) Winner Best Long Form Music Video<br />
            </p>
        </div>

    const esp = 
        <div>
            <p>
                Dirección: Bruno Bancalari<br />
                Producción: Juan Pablo López-Fonseca<br />
                Guión: Natalia Lafourcade, Bruno Bancalari y Juan Pablo López-Fonseca<br />
                DoP: Bruno Bancalari y Juan Pablo López-Fonseca<br />
                Edición: Bruno Bancalari<br />
                Mezcla de Sonido: Alan Ortíz<br />
                Música: Natalia Lafourcade<br />
                Casa Productora: ELEFANTE<br />
                Formato: Documental<br />
                Duración: 31:00 min<br />
                Año: 2017<br />
                Fotografías por: Juan Pablo López-Fonseca<br />
                Sony Music Entertainment México S.A de C.V<br />
                Ganador del Latin Grammy (2017) al Mejor Video en formato largo<br />
            </p>
        </div>

    useLayoutEffect(()=>{

        const lazies = [one,two,three,four,five,six,seven,eight,nine]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {

                    entry.target.src = entry.target.dataset.src;
                            
                }

            })

        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let picStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        picStyle = {

            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>{props.lang == "ES" ? props.info.titulo : props.info.title}</p>
                <div dangerouslySetInnerHTML={{__html: props.vid}}></div>
                <br />
                <img ref={one} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/1.jpg`} alt="1"></img>
                <br />
                <img ref={two} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/2.jpg`} alt="2"></img>
                <br />
                <img ref={three} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/3.jpg`} alt="3"></img>
                <br />
                <img ref={four} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/4.jpg`} alt="4"></img>
                <br />
                <img ref={five} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/5.jpg`} alt="5"></img>
                <br />
                <img ref={six} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/6.jpg`} alt="6"></img>
                <br />
                <img ref={seven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/7.jpg`} alt="7"></img>
                <br />
                <img ref={eight} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/8.jpg`} alt="8"></img>
                <br />
                <img ref={nine} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/musas/9.jpg`} alt="9"></img>
                <br />
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Nat)