import React, { useRef, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../../images/bclose.png"
import White from "../../images/lazy.jpg"

const Hasta = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: one.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: four.current.getBoundingClientRect().left, textAlign: `left`})

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const one = useRef("one")
    const two = useRef("two")
    const three = useRef("three")
    const four = useRef("four")
    const five = useRef("five")
    const six = useRef("six")
    const seven = useRef("seven")
    
    const showHide = props.display ? 'block' : 'none'

    const eng = 
    <div>
        <p>
            What started out for Natalia Lafourcade to be an album of heartbreak (and deep relief),
            ended up turning into a quest for identity that transcended boundaries, and connected to
            thousands of people worldwide. Hasta la raíz (To the Root) is a documentary in the
            form of a diary that follows through a very intimate and personal sight, the whole process
            of creation of the sixth album of the Mexican singer. From the production and recording
            of the songs, to its own transformation with the audience in concerts and touring.
        </p>
        <p>
            The album became the most successful one in Lafourcade’s career, and achieved the
            recognition of the industry with 5 Latin Grammys (2015) and 1 Grammy for best Latin
            rock, urban or alternative album (2016), making us the witness of a transcendent artistic
            process of creation, growth and evolution.
        </p>
        <p>
            Directed by: Bruno Bancalari y Juan Pablo López-Fonseca<br />
            Produced by: Juan Pablo López-Fonseca<br />
            Script: Natalia Lafourcade, Bruno Bancalari and Juan Pablo López-Fonseca<br />
            DoP: Bruno Bancalari, Juan Pablo López-Fonseca, Benji Estrada and Jorge Juárez<br />
            Editing: Bruno Bancalari<br />
            Sound design & mixing: Alan Órtiz.<br />
            Music: Natalia Lafourcade<br />
            Año: 2016<br />
            Photographs by: Dorian Ulises López, Bruno Bancalari & Juan Pablo López-Fonseca<br/>
            Sony Music Entertainment México S.A de C.V<br />
        </p>

    </div>

    const esp = 
    <div>
        <p>
            Lo que para Natalia Lafourcade comenzó siendo un disco de desamor (y profundo desahogo),
            terminó convirtiéndose en una búsqueda de identidad que trascendió fronteras y conectó con
            miles de personas alrededor el mundo. Hasta la raíz es un documental en forma de diario que
            acompaña, desde una mirada muy íntima y personal, todo el proceso de realización del sexto
            album de la cantante mexicana. Desde la producción y grabación de los temas, hasta su propia
            transformación con el público en conciertos y giras.
        </p>
        <p>
            El disco se convirtió en el más exitoso en la carrera de Lafourcade y recibió el reconocimiento
            de la industria con 5 Grammys Latinos (2015) y 1 Grammy Americano (2016) al mejor álbum de
            rock, música urbana o alternativa latina, haciéndonos testigos de un trascendente proceso
            artístico de creación, crecimiento y evolución.
        </p>    
        <p>
            Dirección: Bruno Bancalari y Juan Pablo López-Fonseca<br />
            Producción: Juan Pablo López-Fonseca<br />
            Guión: Natalia Lafourcade, Bruno Bancalari y Juan Pablo López-Fonseca<br />
            DoP: Bruno Bancalari, Juan Pablo López-Fonseca, Benji Estrada y Jorge Juárez<br />
            Edición: Bruno Bancalari<br />
            Diseño Sonoro y Mezcla: Alan Órtiz.<br />
            Música: Natalia Lafourcade<br />
            Año: 2016<br />
            Fotografías por: Dorian Ulises López, Bruno Bancalari y Juan Pablo López-Fonseca<br />
            Sony Music Entertainment México S.A de C.V<br />
        </p>
    </div>

    useLayoutEffect(()=>{

        const lazies = [one,two,three,four, five, six, seven]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let picStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        picStyle = {

            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>{props.lang == "ES" ? props.info.titulo : props.info.title}</p>
                <img ref={one} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/1.jpg`} alt="1"></img>
                <br />
                <img ref={two} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/2.jpg`} alt="2"></img>
                <br />
                <img ref={three} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/3.jpg`} alt="3"></img>
                <br />
                <img ref={four} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/4.jpg`} alt="4"></img>
                <br />
                <img ref={five} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/5.jpg`} alt="5"></img>
                <br />
                <img ref={six} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/6.jpg`} alt="6"></img>
                <br />
                <img ref={seven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hasta/7.jpg`} alt="7"></img>
                <br />
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
                
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Hasta)