import React, { useRef, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../../images/bclose.png"
import White from "../../images/lazy.jpg"

const Hijos = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: one.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: ten.current.getBoundingClientRect().left, textAlign: `left`})

            console.log(props)

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const one = useRef("one")
    const two = useRef("two")
    const three = useRef("three")
    const four = useRef("four")
    const five = useRef("five")
    const six = useRef("six")
    const seven = useRef("seven")
    const eight = useRef("eight")
    const nine = useRef("nine")
    const ten = useRef("ten")
    const eleven = useRef("eleven")
    const twelve = useRef("twelve")
    const thirteen = useRef("thirteen")
    const fourteen = useRef("fourteen")
    
    const showHide = props.display ? 'block' : 'none'

    const eng = 
    <div>
        <p>
        Documentary / Color / 95min / Mexico / 2023</p>
        <p>Different Afro-Mexican musicians from the coast of Oaxaca and the Costa Chica region of Guerrero (Mexico) reveal the story of a community that, through their music, pays tribute to their homeland, to the sea and to everything that connects them to life.<br />Children of the coast is a documentary that portrays folk musicians in their purest form, wrapped in their immediate environment and exalting their musical tradition as an elementary principle in the construction of the identity of today's Mexico.</p>
        <p>Cast:<br />Alejandra Robles Cantante Afromexicana, Chogo Prudente, Cristino “Cuiri” García, Domingo Ayona, El Internacional Mar Azul El Rey de los 7 mares, Esteban Bernal, Esteban Martínez, Pepe Ramos, Raí Prudente.</p>
        <p>Una Comunión, Ángel Céspedes Cantú and Elefante, in co-production with BHD Estudios, Terminal Films and Grande Aguilar Studio, present LOS HIJOS DE LA COSTA. A documentary made thanks to EFICINE-Producción and Estafeta, with the support of Programa Ibermedia, Secretaría de Relaciones Exteriores, Programa de Visitantes de Berlinale Coproduction Market, México Negro A.C., Club Bacocho, Hotel Surf Olas Altas, Casa Kalmar and Los Xiotes Zipolite.</p>
        <p>
            Screenplay, DoP and Directing: Bruno Bancalari<br />
            Delegate Producer: Paola Herrera<br />
            Producers: Ángel Céspedes, Juan Pablo López-Fonseca, Bruno Bancalari<br />
            Co-producers: Alejandro de Icaza, Joakim Ziegler, Øyvind Stiauren, Alan Ortiz<br />
            Research and music production: Ángel Céspedes<br />
            Editing: Bruno Bancalari<br />
            Editing adviser: Pedro González Rubio<br />
            Sound: Carlos Rotzinger<br />
            Sound design: Alejandro de Icaza<br />
            Post-production: Joakim Ziegler<br />
        </p>
        <p>
            FESTIVALS:<br /><br /> 
            Premiere · Official selection <strong>Premio Mezcal</strong> category at the 39º Guadalajara International Film Festival.<br /><br />
            26th Shanghai International Film Festival, official competition in the category <strong>Belt and Road Film Week</strong>.<br /><br />
            18th Mumbai International Film Festival, non-competition section <strong>Mosaics</strong>.
        </p>
        <p>
        <br /><br />Poster by: Manuel Macías (Rayaenmedio)<br />
            Photographs by: Juan Pablo López-Fonseca and Bruno Bancalari
        </p>
    </div>

    const esp = 
    <div>
        <p>Documental / Color / 95min / México / 2023</p>
        <p>El seguimiento de distintos músicos afromexicanos de la Costa de Oaxaca y de la Costa Chica de Guerrero (México) nos adentra en la cultura popular costeña y nos revela la historia de una comunidad que, a través de su música, rinde tributo a su pueblo, al mar, al campo y a aquello que los conecta a la tierra.<br />Los hijos de la costa es un documental que retrata al músico en su estado más puro, envuelto en su entorno inmediato y enalteciendo a la tradición musical como un principio elemental en la construcción de la identidad del México de hoy.</p>
        <p>Con la participación de:<br />Alejandra Robles Cantante Afromexicana, Chogo Prudente, Cristino “Cuiri” García, Domingo Ayona, El Internacional Mar Azul El Rey de los 7 mares, Esteban Bernal, Esteban Martínez, Pepe Ramos, Raí Prudente.</p>
        <p>Una Comunión, Ángel Céspedes Cantú y Elefante, en co-producción con BHD Estudios, Terminal Films y Grande Aguilar Studio, presentan LOS HIJOS DE LA COSTA. Un documental realizado gracias a EFICINE-Producción y Estafeta, con el apoyo de Programa Ibermedia, Secretaría de Relaciones Exteriores, Programa de Visitantes de Berlinale Coproduction Market, México Negro A.C., Club Bacocho, Hotel Surf Olas Altas, Casa Kalmar y Los Xiotes Zipolite.</p>
        <p>
            Guión, dirección y DoP: Bruno Bancalari<br />
            Productora delegada: Paola Herrera<br />
            Productores: Ángel Céspedes, Juan Pablo López-Fonseca, Bruno Bancalari<br />
            Coproductores: Alejandro de Icaza, Joakim Ziegler, Øyvind Stiauren, Alan Ortiz<br />
            Investigación y producción musical: Ángel Céspedes<br />
            Edición: Bruno Bancalari<br />
            Asesor en edición: Pedro González Rubio<br />
            Sonido directo: Carlos Rotzinger<br />
            Diseño Sonoro: Alejandro de Icaza<br />
            Postproducción: Joakim Ziegler<br />
        </p>
        <p>
            FESTIVALES:<br /><br /> 
            Estreno · Competencia oficial <strong>Premio Mezcal</strong> de la 39º edición del Festival Internacional de Cine de Guadalajara.<br /><br />
            26º Festival Internacional de Cine de Shanghai, compitiendo en la categoría <strong>Belt and Road Film Week</strong>.<br /><br />
            18º Festival Internacional de Cine de Mumbai, como parte del ciclo <strong>Mosaics</strong>.
        </p>
        <p>
            <br /><br />Poster por: Manuel Macías (Rayaenmedio)<br />
            Fotografías por: Juan Pablo López-Fonseca y Bruno Bancalari
        </p>
    </div>

    useLayoutEffect(()=>{

        const lazies = [one,two,three,four,five,six,seven,eight,nine,ten,eleven,twelve,thirteen,fourteen]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let picStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        picStyle = {

            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        grafStyle = {
            // width: `380px`,
            width: `500px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

   

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>{props.lang == "ES" ? props.info.titulo : props.info.title}</p>
                <img ref={one} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/one.jpg`} alt="1"></img>
                <br />
                <img ref={two} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/two.jpg`} alt="2"></img>
                <br />
                <img ref={three} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/three.jpg`} alt="3"></img>
                <br />
                <img ref={four} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/four.jpg`} alt="4"></img>
                <br />
                <img ref={five} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/five.jpg`} alt="5"></img>
                <br />
                <img ref={six} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/six.jpg`} alt="6"></img>
                <br />
                <img ref={seven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/seven.jpg`} alt="7"></img>
                <br />
                <img ref={eight} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/eight.jpg`} alt="8"></img>
                <br />
                <img ref={nine} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/nine.jpg`} alt="9"></img>
                <br />
                <img ref={ten} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/ten.jpg`} alt="10"></img>
                <br />
                <img ref={eleven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/eleven.jpg`} alt="11"></img>
                <br />
                <img ref={twelve} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/twelve.jpg`} alt="12"></img>
                <br />
                <img ref={thirteen} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/thirteen.jpg`} alt="13"></img>
                <br />
                <img ref={fourteen} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/films/hijos/fourteen.jpg`} alt="14"></img>
                <br />
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
                
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Hijos)