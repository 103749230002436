import React, { useState } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import Img from "gatsby-image"
import Hijos from "../components/films/hijos"
import Quien from "../components/films/quien"
import Hasta from "../components/films/hasta"
import Nat from "../components/films/nat"
import HeaderDecider from "../components/headerDecider"

const Films = (props) => {

    const [modal, display] = useState(false)
    const [embed, choose] = useState()
    const [mobileInf, info] = useState({})
    const [hijos, displayM1] = useState(false)
    const [quien, displayM2] = useState(false)
    const [hasta, displayM3] = useState(false)
    const [nat, displayM4] = useState(false)

    const winWidth = `${props.width}`
    const winHeight = `${props.height}`

    let gridStyle = {
        display: `grid`,
        // gridTemplateColumns: `${winWidth / 3}px ${winWidth / 3}px ${winWidth / 3}px`,
        gridTemplateColumns: `${winWidth / 2}px ${winWidth / 2}px`,
        position: `relative`,
    }

    let innerDivStyle = {
        position: `relative`,
        // width: winWidth / 3,
        // height: winHeight / 3,
        width: winWidth / 2,
        height: winHeight / 2,
    }

    let picStyle= {
        maxHeight: `100%`,
    }   

    let picCont = {
        display: `flex`,
        justifyContent: `center`,
        height: winHeight / 3,
        height: winHeight / 2,
        overflow: `none`,
    }
 
    if (props.width > 576 && props.width <= 769) {

        gridStyle = {
            ...gridStyle,
            gridTemplateColumns: `${winWidth / 2}px ${winWidth / 2}px`,
        }
    
        innerDivStyle = {
            ...innerDivStyle,
            width: winWidth / 2,
            height: winHeight / 3,
        }

        picStyle= {
            maxHeight: `100%`,
        }   

        picCont = {
            display: `flex`,
            justifyContent: `center`,
            height: winHeight / 3,
            overflow: `none`,
        }
    
    } else if (props.width <= 576) {
    
        gridStyle = {
            ...gridStyle,
            gridTemplateColumns: `${winWidth}px`,
        }
    
        innerDivStyle = {
            ...innerDivStyle,
            width: props.width,
            height: `auto`
        }

        picStyle= {
            maxHeight: `100%`,
        }   

        picCont = {
            display: `flex`,
            justifyContent: `center`,
            maxHeight: winHeight / 3,
            overflow: `none`,
        }
    
    } 

    const modal1On = emby => event => {

        event.preventDefault()

        if ({hijos}.hijos == false) {
            choose(emby.html)
            info(emby.frontmatter)
            displayM1(true)
          } else {
            displayM1(false)
          }
    
    }

    const modal1Off = (event) => {
        event.preventDefault()

        displayM1(false)
    }

    const modal2On = emby => event => {

        event.preventDefault()

        if ({quien}.quien == false) {
            choose(emby.html)
            info(emby.frontmatter)
            displayM2(true)
          } else {
            displayM2(false)
          }
    
    }

    const modal2Off = (event) => {
        event.preventDefault()

        displayM2(false)
    }

    const modal3On = emby => event => {

        event.preventDefault()

        if ({hasta}.hasta == false) {
            choose(emby.html)
            info(emby.frontmatter)
            displayM3(true)
          } else {
            displayM3(false)
          }
    
    }

    const modal3Off = (event) => {
        event.preventDefault()

        displayM3(false)
    }

    const modal4On = emby => event => {

        event.preventDefault()

        if ({hasta}.hasta == false) {
            choose(emby.html)
            info(emby.frontmatter)
            displayM4(true)
          } else {
            displayM4(false)
          }
    
    }

    const modal4Off = (event) => {
        event.preventDefault()

        displayM4(false)
    }

    const refac = props.data.allMarkdownRemark.edges

    const items = refac.map((el, key)=>{

        if (props.width <= 768) {

            if (key === 0) {

                return (

                    <div onClick={modal1On(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )

            } else if (key === 1) {

                return (

                    <div onClick={modal2On(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )

            } else if (key === 2) {

                return (

                    <div onClick={modal4On(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )

            } else if (key === 3) {

                return (

                    <div onClick={modal3On(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )

            }

            

        } else {

            if (key === 0) {
                return (

                    <div onClick={modal1On(el.node) } style={innerDivStyle} key={key}>
    
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={"overlay"}>
                            <p className="gridText" id={key}>
                                {props.lang == "ES" ? el.node.frontmatter.titulo : el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                <br />
                                {el.node.frontmatter.more != "none" ? el.node.frontmatter.more : " "}
                                {el.node.frontmatter.more ? <br /> : " "}
                                {el.node.frontmatter.director}
                                {el.node.frontmatter.director ? <br /> : " "}
                                {el.node.frontmatter.extra ? el.node.frontmatter.extra : " "}
                                {el.node.frontmatter.extra ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
    
                    </div>
        
                )
            } else if (key === 1) {

                return (

                    <div onClick={modal2On(el.node) } style={innerDivStyle} key={key}>
    
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={"overlay"}>
                            <p className="gridText" id={key}>
                                {props.lang == "ES" ? el.node.frontmatter.titulo : el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                <br />
                                {el.node.frontmatter.director}
                                {el.node.frontmatter.director ? <br /> : " "}
                                {el.node.frontmatter.producer ? el.node.frontmatter.producer : " "}
                                {el.node.frontmatter.producer ?  <br /> : " "}
                                {el.node.frontmatter.extra ? el.node.frontmatter.extra : " "}
                                {el.node.frontmatter.extra ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
    
                    </div>
        
                )

            } else if (key === 2) {

                return (

                    <div onClick={modal4On(el.node) } style={innerDivStyle} key={key}>
    
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={"overlay"}>
                            <p className="gridText" id={key}>
                                {props.lang == "ES" ? el.node.frontmatter.titulo : el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                <br />
                                {el.node.frontmatter.director}
                                {el.node.frontmatter.director ? <br /> : " "}
                                {el.node.frontmatter.producer ? el.node.frontmatter.producer : " "}
                                {el.node.frontmatter.producer ?  <br /> : " "}
                                {el.node.frontmatter.extra ? el.node.frontmatter.extra : " "}
                                {el.node.frontmatter.extra ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
    
                    </div>
        
                )

            } else if (key === 3) {

                return (

                    <div onClick={modal3On(el.node) } style={innerDivStyle} key={key}>
    
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={"overlay"}>
                            <p className="gridText" id={key}>
                                {props.lang == "ES" ? el.node.frontmatter.titulo : el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                <br />
                                {el.node.frontmatter.director}
                                {el.node.frontmatter.director ? <br /> : " "}
                                {el.node.frontmatter.producer ? el.node.frontmatter.producer : " "}
                                {el.node.frontmatter.producer ?  <br /> : " "}
                                {el.node.frontmatter.extra ? el.node.frontmatter.extra : " "}
                                {el.node.frontmatter.extra ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
    
                    </div>
        
                )

            }

        }

    })

    return [ 
        
        <HeaderDecider />,

        <div style={gridStyle}>
            {items}
        </div>,
        <Quien off={modal2Off} display={quien} info={mobileInf} vid={embed} />,
        <Hijos off={modal1Off} display={hijos} info={mobileInf} />,
        <Nat off={modal4Off} display={nat} info={mobileInf} vid={embed} />,
        <Hasta off={modal3Off} display={hasta} info={mobileInf} />,
    ]

}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        width: state.width,
        height: state.height
    }
}

export default connect(mapStateToProps)(Films)

export const pageQuery = graphql `{
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/films/"}}, sort: {fields: frontmatter___order, order: ASC}) {
        edges {
          node {
            frontmatter {
                title,
                titulo,
                company,
                more,
                producer,
                director,
                genre,
                genero,
                featuredImage {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                }
              }
            }
            html
          }
        }
      }  
} 
`